<template>
  <div class="lesson-create flex-column">
    <div class="lesson-create-wraaper manage-wrapper">
      <div class="tabs__header create-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="编辑课节" name="create">
            <div class="addclass">
              <div class="content">
                <div class="cont-mid">
                  <el-form ref="form" :rules="rules" :model="form" label-width="200px">
                    <el-form-item label="沙龙课节名称：" prop="lessonsName">
                      <el-input v-model="form.lessonsName" maxlength="100" show-word-limit style="width:555px"
                        :disabled="isDisable"></el-input>
                    </el-form-item>
                    <el-form-item label="上课时间：" required>
                      <dateTimePikcer @dateTimePicker="handleTimeChange" :endTime="form.lessonsEndTime"
                        :beginTime="form.lessonsBeginTime" :isDisabled="isDisable">
                      </dateTimePikcer>
                    </el-form-item>

                    <div class="teahcers flex-align">
                      <el-form-item label="授课老师：" prop="teacherId" required>
                        <teacherSelect :requestType="'teacher'" :courseId="Number(currentProject)"
                          :role="form.teacherRole" :selected="form.teacherId" @teacherSelect="handleTeacherChange" />

                      </el-form-item>
                      <el-form-item label="助教老师：" class label-width="100px">
                        <teacherSelect :requestType="'assistant'" :courseId="Number(currentProject)"
                          :role="form.assistantRole" :selected="form.assistantId"
                          @teacherSelect="handleTeacherChange" />
                      </el-form-item>
                    </div>

                    <el-form-item label="参训用户：" prop="lessonsPattern" required>
                      <div style="margin-bottom:20px;">
                        <el-radio-group v-model="form.lessonsPattern" @input="changePattern">
                          <el-radio :label="0">按人选</el-radio>
                          <el-radio :label="1">按用户组</el-radio>
                        </el-radio-group>
                      </div>
                      <div v-if="form.lessonsPattern" style="margin-left:-100px;">
                        <UserGroup :id="Number(currentProject)" @userGroup="changeUserGroup"
                          :userGroupVal="form.userOrGroupIds" :proId="form.projectId"
                          :isEdit="true"></UserGroup>
                      </div>
                      <div v-else>
                        <el-transfer v-model="form.userOrGroupIds" :data="userList" :titles="['全部用户', '已选用户']"
                          :button-texts="['取消','选择']" filterable filter-placeholder="按姓名或手机号"
                          :filter-method="filterMethod" :props="{key:'id',label:'name',disabled :'disabled'}"
                          :disabled="!isEdit">
                          <span slot-scope="{ option }">{{ option.name }} - {{ option.phone }}</span>
                        </el-transfer>
                      </div>
                    </el-form-item>

                    <el-form-item label="直播课件：" :required="true">
                      <uploadFile @uploadFile="handleFileChange" :name="'liveIdentifiers'" :list="form.liveFileNames"
                        :isDisabled="isDisable" :sourceType=2 />
                    </el-form-item>

                    <el-form-item label="课节附件：">
                      <uploadFile @uploadFile="handleFileChange" :name="'lessonIdentifiers'"
                        :list="form.lessonFileNames" :isDisabled="isDisable" :sourceType=2 />
                    </el-form-item>

                    <el-form-item label="回放视频：">
                      <uploadFile @uploadFile="handleFileChange" :name="'videoIdentifiers'" :list="form.videoNames"
                        :tips="{image:false,url:true}" :sourceType=1 />
                    </el-form-item>

                    <el-form-item class="el-form-item-btns">
                      <el-button @click="$router.push(`/salon/lessons/${form.courseProjectId}?breadNumber=4`)">取消
                      </el-button>
                      <el-button type="primary" @click="onSubmit">确定</el-button>
                    </el-form-item>
                    <br />
                    <br />
                  </el-form>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
  import {
    mapState
  } from "vuex";
  import teacherSelect from "../components/teacherSelect.vue";
  export default {
    name: "newcourse",
    data() {
      return {
        activeName: "create",
        isDisable: false,
        isEdit: false,
        userList: [],
        form: {
          lessonsPattern: 1,
          teacherId: "", //老师id
          assistantId: "", //助教id
          userOrGroupIds: [],
          userGroupIds: [],
          maxUsers: 14,
          // autoOnStage: false,
          teachMode: 1, //教学模式  1在线教室  2智慧教室
          platformCount: 8, //台上人数  取 V 后面的值
          isHd: 0, // 是否高清 0 非高清 1高清 2全高清
          isAutoOnstage: 1, //学生进入教室是否自动上台 0自动 1不自动
          replay: false, //是否回访
        },
        rules: {
          lessonsName: [{
            required: true,
            message: "请输入课节名称",
            trigger: "blur"
          }],
          teacherId: [{
            required: true,
            message: "请选择授课老师",
            trigger: "change"
          }],
          teacherName: [{
            required: true,
            message: "请填写教师名称",
            trigger: "change"
          }],
          userGroupRules: [{
            required: true,
            message: "请选择用户组",
            trigger: "blur"
          }],
          video: [{
            required: true
          }],
        },
      };
    },
    computed: {
      ...mapState({
        currentProject: state => state.user.currentProject.id,
      })
    },
    components: {
      teacherSelect
    },
    methods: {
      handleTeacherChange(data) {
        console.log(data);
        let type = data.type === 'teacher' ? true : (data.type === 'assistant' ? false : '')
        if (type) {
          this.form.teacherId = data.teacherId
          this.form.teacherRole = data.role
        } else {
          this.form.assistantId = data.teacherId
          this.form.assistantRole = data.role
        }
        // console.log(this.form);
      },
      // 改变参训用户类型
      changePattern(type) {
        console.log('参训用户类型', type)
        this.userOrGroupIds = null;
      },
      handleTimeChange(data) {
        // console.log(data);
        this.form.lessonsBeginTime = data.bTime
        this.form.lessonsEndTime = data.eTime
      },
      handleFileChange(data) {
        // console.log(data);
        this.form[data.name] = data.fileMD5List;
      },
      async onSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.commitForm();
          }
        })
      },
      async commitForm() {
        console.log(this.form);
        let resData = await this.$Api.Course.editSalonLesson(this.form);
        console.log(resData);
        if (resData.data.code === 200) {
          this.$notify.success({
            title: "成功",
            message: "编辑课节成功",
          });
          setTimeout(() => {
            this.$router.push(`/salon/lessons/${this.$route.query.salonId}?breadNumber=4`);
          }, 500);
        } else {
          this.$notify.error({
            title: "错误",
            message: resData.msg
          });
        }
      },
      // 切换用户组
      changeUserGroup(data) {
        console.log(data);
        this.form.trancheId = data.trancheId;
        this.form.projectId = data.projectId;
        this.form.userOrGroupIds = data.userGroupArr;
        this.form.userGroupIds = data.userGroupArr;
        // console.log(this.form);
      },
      //获取用户列表 （按人选）
      async getUserList() {
        this.list = [];
        let params = {
          projectId: this.currentProject,
        };
        console.log(params);
        let resData = await this.$Api.Course.getSalonTeacher(params);
        console.log('人员列表', resData);
        this.userList = resData.data;
      },
      // 人员筛选
      filterMethod(query, item) {
        let str = String(item.phone)
        if (item.name.indexOf(query) > -1) {
          return item.name.indexOf(query) > -1;
        } else if (str.indexOf(query) > -1) {
          return str.indexOf(query) > -1
        } else {
          return
        }
      },
      // 请求课节信息
      async getLessonInfo() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.getSalonLessonDetail(id);
        console.log('沙龙课节详情', resData.data);
        if (!resData) return
        this.form = resData.data;
        this.form.lessonsPattern = Number(this.form.lessonsPattern);
        this.getGroupInfo();
      },
      // 获取用户组选项
      async getGroupInfo() {
        let id = this.form.projectId;
        let resData = await this.$Api.Course.GroupInfo(id);
        this.groupInfo = resData.data;
      },
    },
    mounted() {
      this.getUserList();
      this.getLessonInfo();
      // this.getLessonInfo()
    },
    activated() {
      // this.getLessonInfo()
    },
    deactivated() {
      this.form = {
        maxUsers: 2,
        autoOnStage: false,
        teacher: {
          id: 0
        },
        assistant: {
          id: 0
        },
      }
      this.$refs["form"].resetFields();
    },
    created() {}
  };
</script>

<style lang="less" scoped>
  @import "../../manage_project/less/lesson_commen.less";
  @import "../../manage_project/less/manage-project.less";
</style>